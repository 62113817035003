@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* splide css override */
ul.splide__pagination {
  margin-top: 20px;
  bottom: 0px;
}
.splide__pagination li button {
  background-color: white;
  height: 15px;
  width: 15px;
  border: 2px solid #707070;
}
.splide__arrow {
  background-color: #33333369 !important;
  height: 1.3em !important;
  width: 1.3em !important;
}
.splide__arrow svg {
  fill: #000;
  height: 0.7em !important;
  width: 0.7em !important;
}
.splide__pagination li button.is-active {
  border: none;
  transform: scale(1);
  box-shadow: 0px 0px 50px 0px rgba(255, 0, 4, 1);
  background-color: white;
}
/* end of splide css override */
/* Joyride css override */
.react-joyride__spotlight {
  border-radius: 20px !important;
}
button[aria-label="Next"] {
  display: none;
  background-color: rgb(255, 0, 68);
}
button[aria-label="Last"] {
  display: none;
  background-color: rgb(255, 0, 68);
}
.react-joyride__tooltip :last-child {
  margin-top: 0 !important;
}
.__floater {
  /* max-width: 80% !important; */
  padding: 0 !important;
}
.__floater__arrow {
  display: none;
}
.react-joyride__tooltip {
  padding: 0 !important;
}
.iframe-container {
  position: relative;
  width: 100%;
  /* padding-bottom: 50%; */
  background: lightgrey;
  /* aspect-ratio: 16/9; */
}

.iframe-container iframe {
  position: absolute;
  width: 100%;

  height: 100vh;
  padding-bottom: 80px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* border: 1px solid green; */

  /* border: 1px solid red; */
}

@media screen and (min-width: 768px) {
  .iframe-container iframe {
    height: 100vh;
  }
}

.carousel.carousel-slider .control-arrow {
  background: gray !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
}
